import {Input} from 'src/atoms/Input/Input';
import {StyledAssistiveText, StyledLabel} from 'src/atoms/Input/styled';
import {IntRange} from 'type-fest';

import type {InputProps} from 'src/atoms/Input/Input';

import {StyledContainer, StyledEmailField} from './styled';

export interface EmailFieldProps extends InputProps {
  spacing?: IntRange<0, 100>;
  label?: string;
  floatingLabel?: boolean;
}

const EmailField = ({label = '', floatingLabel = true, spacing = 0, ...props}: EmailFieldProps) => {
  return (
    <StyledContainer $spacing={spacing}>
      <StyledEmailField>
        {label !== '' && !floatingLabel && (
          <StyledLabel $floating={floatingLabel} htmlFor={props.name}>
            {label}
          </StyledLabel>
        )}

        <Input
          type="email"
          inputMode="email"
          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
          floatingLabel={floatingLabel}
          {...props}
        />

        {label !== '' && floatingLabel && (
          <StyledLabel $floating={floatingLabel} htmlFor={props.name} data-floating={true}>
            {label}
          </StyledLabel>
        )}
      </StyledEmailField>

      {props?.error && <StyledAssistiveText>{props.error}</StyledAssistiveText>}
    </StyledContainer>
  );
};

export {EmailField};
