import {useRouter} from 'next/router';

import {Button, Icon, List, PopUp, Typography} from '@kasta-io/components';

import s from './KycStartVerificationDialog.module.scss';

interface Props {
  type: 'cash' | 'crypto';
  isVisible: boolean;
  togglePopUp: () => void;
}

const Item = ({icon, content}: {icon: string; content: string}) => {
  return (
    <div className={s.item}>
      <Icon name={icon} color="var(--colors-text-onBackgroundMedium)" />
      <Typography variant="body2" color="var(--colors-text-onBackgroundMedium)">
        {content}
      </Typography>
    </div>
  );
};

const cryptoItems = [
  {
    id: 'wallet',
    children: <Item icon={'wallet'} content={'Open crypto account'} />,
  },
  {
    id: 'swap',
    children: <Item icon={'swap'} content={'Instantly convert currencies'} />,
  },
  {
    id: 'send',
    children: <Item icon={'send'} content={'Send crypto to friends on Ka.app—free and instant'} />,
  },
  {
    id: 'kasta',
    children: <Item icon={'kasta'} content={'Lower fees & earn rewards with Ka.app tiers'} />,
  },
];

const otherItems = [
  {
    id: 'wallet',
    children: <Item icon={'wallet'} content={'Open crypto & euro accounts'} />,
  },
  {
    id: 'credit-card',
    children: <Item icon={'credit-card'} content={'Personal Visa debit card'} />,
  },
  {
    id: 'swap',
    children: <Item icon={'swap'} content={'Instantly convert currencies'} />,
  },
  {
    id: 'send',
    children: <Item icon={'send'} content={'Send euros or crypto to friends on Ka.app—free and instant'} />,
  },
  {
    id: 'kasta',
    children: <Item icon={'kasta'} content={'Lower fees & earn rewards with Ka.app tiers'} />,
  },
];

export const KycStartVerificationDialog = ({type, isVisible, togglePopUp}: Props) => {
  const router = useRouter();

  const navigateToKyc = async (): Promise<void> => {
    togglePopUp();

    if (type === 'cash') {
      await router.replace('/cash/kyc/explained');
    }

    if (type === 'crypto') {
      await router.push(`/kyc`);
    }
  };

  return (
    <PopUp
      open={isVisible}
      popOver
      onClose={togglePopUp}
      closeOnBackdropClick
      hasCloseButton={false}
      contentAlignment="left"
      title={
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Typography as="h6" variant="h7" color="var(--colors-text-onBackgroundMedium)" style={{flex: '1'}}>
            Verify your identity to benefit from ka.app
          </Typography>
          <Icon type="custom" name="yellow-random-column" size={80} style={{flex: '0 0 80px'}} />
        </div>
      }
      footer={<Button onClick={navigateToKyc}>Continue to verification</Button>}>
      <List gap={4} items={type === 'crypto' ? cryptoItems : otherItems} />
    </PopUp>
  );
};
