import {useEffect, useState} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';
import {Typography} from 'src/atoms/Typography/Typography';

import {StyledFAQ, StyledFAQContent, StyledFAQItem, StyledFAQItemHeader} from './styled';

export interface FAQItemProps {
  title: string;
  content: string | JSX.Element;
  withIcon?: boolean;
  defaultOpen?: boolean;
}

export interface FAQProps {
  faqs?: FAQItemProps[];
  expandAll?: boolean;
  showIcon?: boolean;
  contentHeight?: number;
}

const FAQ = ({faqs = [], expandAll = false, showIcon = false, contentHeight = 500, ...props}: FAQProps) => {
  const [openStates, setOpenStates] = useState<boolean[]>([]);

  useEffect(() => {
    setOpenStates(faqs.map(faq => faq.defaultOpen ?? expandAll));
  }, [expandAll, faqs]);

  if (faqs.length === 0) {
    return null;
  }

  const toggleItem = (index: number) => {
    setOpenStates(prev => prev.map((state, i) => (i === index ? !state : state)));
  };

  return (
    <StyledFAQ {...props} role="list">
      {faqs.map((faq, index) => {
        const isOpen = openStates[index] ?? false;

        return (
          <StyledFAQItem
            key={index}
            onClick={() => toggleItem(index)}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && toggleItem(index)}
            tabIndex={0}
            role="listitem"
            aria-expanded={isOpen}>
            <StyledFAQItemHeader>
              <Typography variant="h7" as="h6" color="var(--colors-text-onBackgroundMedium)">
                {faq.title}
              </Typography>
              {showIcon ? (
                <Icon
                  type="kastaicon"
                  name={isOpen ? 'chevron-up' : 'chevron-down'}
                  size={24}
                  color="var(--colors-primary-500)"
                  role="img"
                  aria-label={isOpen ? 'Collapse section' : 'Expand section'}
                />
              ) : null}
            </StyledFAQItemHeader>

            <StyledFAQContent $isOpen={isOpen} $contentHeight={contentHeight} aria-hidden={!isOpen}>
              {typeof faq.content === 'string' ? (
                <Typography variant="body2" as="label" color="var(--colors-text-onBackgroundMedium)">
                  {faq.content}
                </Typography>
              ) : (
                faq.content
              )}
            </StyledFAQContent>
          </StyledFAQItem>
        );
      })}
    </StyledFAQ>
  );
};

export {FAQ};
