import viewports from '@kasta-io/tokens/primitives/viewport.json';
import {DEFAULT_RADIUS} from 'src/lib/constants';
import {css} from 'styled-components';

import {DefaultTransition} from './transitions';

interface InputProps {
  $floatingLabel?: boolean;
  $bold?: boolean;
  $spacing?: number;
  $disabled?: boolean;
  disabled?: boolean;
}

export const DefaultLabel = css`
  font-family: var(--font-styles-subtitle1-family);
  font-size: var(--font-styles-subtitle1-size);
  font-style: normal;
  font-weight: var(--font-styles-subtitle1-weight);
  line-height: var(--font-styles-subtitle1-lineHeight);
  color: var(--colors-text-onBackgroundMedium);
  ${DefaultTransition()};
`;

export const DefaultInput = css<InputProps>`
  position: relative;
  width: 100%;
  padding: ${({$floatingLabel}: InputProps) => ($floatingLabel ? '24px 16px 8px' : `16px`)};
  font-size: var(--font-styles-subtitle2-size);
  font-weight: ${({$bold}: InputProps) => ($bold ? '700' : '500')};
  line-height: var(--font-styles-subtitle2-lineHeight);
  color: var(--colors-text-onBackgroundMedium);
  text-align: left;
  letter-spacing: var(--font-styles-subtitle2-spacing);
  background-color: var(--colors-background-paper);
  border: 1px solid var(--colors-text-onBackgroundMedium);
  border-radius: ${DEFAULT_RADIUS}px;
  outline: none;
  ${DefaultTransition()};

  ${props =>
    (props.disabled || props.$disabled) &&
    css`
      color: var(--colors-text-onBackgroundUltraLight);
      pointer-events: none;
      border-color: var(--colors-text-onBackgroundUltraLight);
    `}
`;

export const DefaultBlockPadding = css`
  padding: 24px;
`;

export const DefaultBlockRadius = css`
  border-radius: ${DEFAULT_RADIUS}px;
`;

export const BaseFullScreen = css`
  @media screen and (max-width: ${viewports['tablet']}) {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
`;
