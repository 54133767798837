import {useEffect, useState} from 'react';
import { useRouter } from 'next/router';

import PageLayout from '@/layout/PageLayout';
import * as Sentry from '@sentry/react';

import AppProviders from '@/contexts/AppProviders';
import useClientOfflineControl from '@/hooks/useClientOfflineControl';
import {useFirebaseUser} from '@/hooks/useFirebaseUser';
import {errorMonitoringService} from '@/services/error/ErrorMonitoringService';
import {pinService} from '@/services/pin/PinService';

import ErrorPage from '@/components/ErrorPage/ErrorPage';
import PageInitiator from '@/components/PageInitiator';

import '@kasta-io/tokens/dist/css/index.css';
import '@kasta-io/css-reset/index.css';
import '@kasta-io/icons/index.css';
import 'primereact/resources/primereact.min.css';

import {GTMScript} from '@/components/heads/GTM';

import '../styles/globals.scss';

import dynamic from 'next/dynamic';

import useServiceWorkerUpdate from '@/hooks/useServiceWorkerUpdate';

import type {NextPageWithLayout} from '@/types/generics';
import type {User} from 'firebase/auth';
import type {AppProps} from 'next/app';

const AppHeadDynamic = dynamic(() => import('@/components/heads/AppHead'), {ssr: false});

const pinValidationInterval = 1 * 60 * 1000; // 1 minute

declare global {
  interface Window {
    flutterReady: boolean;
    flutter_inappwebview: {
      callHandler(handlerName: string, ...args: any): Promise<any>;
    };
    CookieConsent?: {
      consent: {
        marketing: boolean;
      };
    };
  }
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export type SessionStatesProps = {
  fbUser: User | null;
  fbUserOnce: boolean;
  isNativeWrapper?: boolean;
};

export type PageComponentPropType<T> = NextPageWithLayout<T & SessionStatesProps>;

export default function MyApp({Component, pageProps}: AppPropsWithLayout) {
  // Session long states
  const fbUserProps = useFirebaseUser();
  const router = useRouter();

  const fbUser = fbUserProps.fbUser;

  // Vars to store the necessary data
  const [token, setToken] = useState<string>();
  const [isFlutterReady, setIsFlutterReady] = useState(false);

  // Step 1: Fetch and save the token
  useEffect(() => {
    const getToken = async () => {
      const token = await fbUser?.getIdToken();
      setToken(token);
    };

    getToken();
  }, [fbUser]);

  useEffect(() => {
    const checkPinTimeout = () => {
      const isPinTimedOut = pinService.isPinTimedOut();

      // Here we can use the user's last auth identifier as a proxy for "isUserLoggedIn",
      // When the user logs out, their last identifier gets cleared.
      // So if identifier exists, then the user is logged in.
      const isUserLoggedIn =
        localStorage.getItem('email_authentication_identifier') ||
        localStorage.getItem('google_authentication_identifier') ||
        localStorage.getItem('phone_number_authentication_identifier');

      if (isUserLoggedIn && isPinTimedOut && router.pathname !== '/login' && !router.pathname.includes('signup')) {
        router.push('/login');
      }
    };

    const interval = setInterval(checkPinTimeout, pinValidationInterval);

    return () => clearInterval(interval);
  }, [router]);

  // Step 2: Wait for Flutter to be ready
  if (!isFlutterReady && typeof window !== 'undefined' && window.flutterReady) {
    setIsFlutterReady(true);
  }

  // Step 3: Call the Flutter handler, passing the token as argument
  useEffect(() => {
    if (!token || !isFlutterReady) return;

    const handleResponseFromFlutter = async () => {
      await window.flutter_inappwebview.callHandler('getToken', {
        token,
        isAnonymous: fbUser?.isAnonymous,
      });
    };

    handleResponseFromFlutter();

    // THIS NEEDS TO EXIST IN THE FLUTTER SIDE:
    // https://inappwebview.dev/docs/webview/javascript/communication/#javascript-handlers
    //
    // onWebViewCreated: (controller) {
    //   controller.addJavaScriptHandler(handlerName: 'getToken', callback: (args) {
    //     print(args); // Should print the token and test string...
    //   });
    // }
  }, [fbUser?.isAnonymous, isFlutterReady, token]);

  useClientOfflineControl();
  useServiceWorkerUpdate();

  // Use the layout defined at the page level, if any
  const getLayout =
    Component.getLayout ??
    (page => (
      <PageInitiator {...page.props}>
        <PageLayout>{page}</PageLayout>
      </PageInitiator>
    ));

  return (
    <Sentry.ErrorBoundary fallback={Fallback} onError={errorMonitoringService.logError}>
      {/* required for PopUp components from design system */}
      <div id="popup-container"></div>

      <div>
        <AppHeadDynamic />
        {/* Google tag manager should be out heads*/}
        <GTMScript />
        <AppProviders pageProps={pageProps}>
          {getLayout(
            <Component
              {...{
                isNativeWrapper: isFlutterReady,
                ...fbUserProps,
                ...pageProps,
              }}
            />,
          )}
        </AppProviders>
      </div>
    </Sentry.ErrorBoundary>
  );
}

function Fallback() {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <ErrorPage
      buttonLabel="Try Again"
      description="Please try again or contact the support in case this issue continues."
    />
  );
}
