import styled, {css} from 'styled-components';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';

const getIcon = ({icon = '', type = 'custom'}: {icon: string; type: 'custom' | 'payments' | 'flags'}) => {
  try {
    // eslint-disable-next-line
    const file = require(`@kasta-io/icons/files/${type}/${icon}.svg`);
    return file.default.src;
  } catch {
    return '';
  }
};

interface IconProps {
  $color?: ColorType;
  $size: number;
  $name?: string;
  $background?: ColorType;
  $rounded?: boolean;
}

const StyledKastaIcon = styled.span<IconProps>`
  width: ${({$size}: IconProps) => `${$size}px`};
  height: ${({$size}: IconProps) => `${$size}px`};
  font-size: ${({$size}: IconProps) => `${$size}px`};
  color: ${({$color}: IconProps) => $color};
  text-align: center;
`;

const StyledCustomIcon = styled.span<IconProps>`
  display: inline-block;
  width: ${({$size}: IconProps) => `${$size}px`};
  height: ${({$size}: IconProps) => `${$size}px`};
  background-image: url(${props => props.$name && getIcon({icon: props.$name, type: 'custom'})});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const StyledImageryIcon = styled.span<IconProps>`
  display: inline-block;
  width: ${({$size}: IconProps) => `${$size}px`};
  height: ${({$size}: IconProps) => `${$size}px`};
  background-color: ${({$background}: IconProps) => `${$background}`};
  background-image: url(${props => props.$name && getIcon({icon: props.$name, type: 'custom'})});
  background-repeat: no-repeat;
  background-position: center;
  background-size: calc(100% - 6px);
  border-radius: ${({$rounded}: IconProps) => ($rounded ? '50%' : '12px')};
`;

const StyledPaymentIcon = styled.span<IconProps>`
  display: inline-block;
  width: ${({$size}: IconProps) => `${$size}px`};
  height: ${({$size}: IconProps) => `${$size}px`};
  background-image: url(${props => props.$name && getIcon({icon: props.$name, type: 'payments'})});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  ${({$rounded}: IconProps) =>
    $rounded &&
    css`
      border-radius: 50%;
    `}
`;

const StyledFlag = styled.span<IconProps>`
  display: inline-block;
  width: ${({$size}: IconProps) => `${$size}px`};
  height: ${({$size}: IconProps) => `${$size}px`};
  background-image: url(${props => props.$name && getIcon({icon: props.$name, type: 'flags'})});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export {StyledKastaIcon, StyledCustomIcon, StyledImageryIcon, StyledPaymentIcon, StyledFlag};
