export const INPUT_DEBOUNCE_TIMEOUT = 500;
export const INPUT_FILLED_CLASS = 'filled';

export const POPUP_PORTAL_NODE = 'popup-container';
export const POPUP_ANIMATION_TIMEOUT = 250;

export const ALERT_TIMEOUT = 5000;
export const ALERT_ANIMATION_TIMEOUT = 250;

export const TOAST_TIMEOUT = 5000;
export const TOAST_ANIMATION_TIMEOUT = 250;

export const DEFAULT_CURRENCY = 'USD';

export const DEFAULT_RADIUS = 12; // value in px
