import React from 'react';

import {PopUpProvider} from '@kasta-io/components';
import {Analytics as VercelAnalytics} from '@vercel/analytics/react';

import AccountContextProvider from '@/contexts/AccountContext';
import AppConfigContextProvider from '@/contexts/AppConfigContext';
import {BuyCryptoProvider} from '@/contexts/BuyCryptoContext';
import {CashBalanceAuthProvider} from '@/contexts/CashBalanceAuthContext';
import {CashHistoryAuthProvider} from '@/contexts/CashHistoryAuthContext';
import ComplianceContextProvider from '@/contexts/ComplianceContext';
import ExchangeRatesProvider from '@/contexts/ExchangeRatesContext';
import PendingActionsContextProvider from '@/contexts/PendingActionsContext';
import TransactionsContextProvider from '@/contexts/TransactionsContext';
import UserContextProvider from '@/contexts/UserContext';

import LocationControl from '@/components/LocationControl/LocationControl';
import PrivateRouteSafetyControl from '@/components/PrivateRouteSafetyControl';
import {TrackingControl} from '@/components/TrackingControl/TrackingControl';

import type {SessionStatesProps} from '@/pages/_app';

import UserInfo from './UserInfo/UserInfo';
import { LocationProvider } from '@/contexts/LocationContext';

export type PageInitialProps = SessionStatesProps;

export default function PageInitiator({
  isNativeWrapper,
  children,
  ...fbUserProps
}: PageInitialProps & {
  children: React.ReactNode;
}) {
  return (
    <>
      <UserContextProvider {...fbUserProps} isNativeWrapper={isNativeWrapper}>
        <LocationControl>
          <ExchangeRatesProvider>
            <AppConfigContextProvider>
              <PopUpProvider>
                <ComplianceContextProvider>
                  <AccountContextProvider>
                    <CashBalanceAuthProvider>
                      <CashHistoryAuthProvider>
                        <TransactionsContextProvider>
                          <TrackingControl />
                          <PrivateRouteSafetyControl>
                            <BuyCryptoProvider>
                              <PendingActionsContextProvider>
                                <LocationProvider>
                                  <>
                                    <UserInfo />
                                    {children}
                                  </>
                                </LocationProvider>
                              </PendingActionsContextProvider>
                            </BuyCryptoProvider>
                          </PrivateRouteSafetyControl>
                        </TransactionsContextProvider>
                      </CashHistoryAuthProvider>
                    </CashBalanceAuthProvider>
                  </AccountContextProvider>
                </ComplianceContextProvider>
              </PopUpProvider>
            </AppConfigContextProvider>
          </ExchangeRatesProvider>
        </LocationControl>
      </UserContextProvider>
      <VercelAnalytics />
    </>
  );
}
