export const KASTA_ICONS = [
  'adjust',
  'apple',
  'arrow-left',
  'arrow-right',
  'arrow-receive',
  'arrow-send',
  'bank-2',
  'bank-3',
  'burn',
  'buy',
  'calc',
  'calendar',
  'camera',
  'cart',
  'cash-account-2',
  'chat',
  'check',
  'checkbox',
  'checkbox-checked',
  'check-circle',
  'chevron-down',
  'chevron-up',
  'chevron-left',
  'chevron-right',
  'close-big',
  'close-small',
  'compare',
  'confused',
  'copy',
  'credit-card',
  'crypto',
  'cupcake',
  'delete',
  'deposit',
  'dice-1',
  'dice-2',
  'dice-3',
  'dice-5',
  'document-download',
  'double-check',
  'double-dash',
  'easy-swap',
  'edit',
  'edit-2',
  'ellipsis',
  'expanded',
  'facebook',
  'face-id',
  'fail-circle',
  'feather',
  'filter',
  'fingerprint',
  'freeze',
  'gift',
  'google-play',
  'hamburger',
  'handshake',
  'happy',
  'heart',
  'hide',
  'history',
  'home',
  'ice-cream',
  'id-card',
  'image',
  'info',
  'instagram',
  'kasta',
  'kyc-1',
  'kyc-2',
  'language',
  'lightbulb',
  'link',
  'linkedin',
  'location',
  'lolly',
  'mail',
  'medium',
  'menu-alt',
  'minus',
  'minus-circle',
  'more',
  'network',
  'note',
  'notebook',
  'notification',
  'passport',
  'phone',
  'pie-chart',
  'plus',
  'plus-circle',
  'point',
  'portfolio',
  'qrcode',
  'radio',
  'radio-filled',
  'refresh',
  'refund',
  'sad',
  'scan',
  'search',
  'security',
  'sell-bag',
  'send',
  'settings',
  'share',
  'shield',
  'signout',
  'show',
  'star',
  'star-filled',
  'statement',
  'stopwatch',
  'support',
  'swap',
  'switch',
  'telegram',
  'test-tube',
  'ticket',
  'tiktok',
  'transfer',
  'trending-down',
  'trending-up',
  'twitter',
  'user',
  'user-plus',
  'vault',
  'wallet',
  'warning',
  'whatsapp',
  'withdraw',
  'x',
  'youtube',
];

export const CUSTOM_ICONS = [
  'bulletpoint',
  'cash-account',
  'google',
  'google-2fa',
  'google-authenticator',
  'google-pay',
  'kasta-shorticon',
  'loading',
  'mastercard',
  'notification-dot',
  'visa',
];

export const IMAGERY_ICONS = [
  'tier0',
  'tier1',
  'tier2',
  'tier3',
  'tier6',
  'tier7',
  'tier8',
  'card',
  'coins',
  'yellow-random-column',
];

export const PAYMENT_ICONS = [
  'ada',
  'apt',
  'avax',
  'axs',
  'bnb',
  'bonk',
  'brett',
  'btc',
  'busd',
  'doge',
  'dot',
  'eth',
  'eur',
  'floki',
  'inj',
  'kasta-inverted',
  'kasta',
  'link',
  'mastercard',
  'matic',
  'near',
  'pepe',
  'pol',
  'sand',
  'shib',
  'sol',
  'sui',
  'ton',
  'trump',
  'trx',
  'uni',
  'usd',
  'usdc',
  'usdt',
  'visa',
  'wif',
  'xrp',
];

/*
 * Country codes following ISO 3166
 * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 */
export const FLAGS = ['ee', 'es', 'se', 'ch', 'ca', 'br', 've'];
