import {useCallback, useRef, useState} from 'react';

import {CardAuthorizationAction} from '@/types/pendingActions';
import {usePopUp} from '@kasta-io/components';

import {pinService} from '@/services/pin/PinService';

export const useAuthorizePaymentDialog = () => {
  const actionConsentId = useRef<string>();
  const [userDeclined, setUserDeclined] = useState(false);
  const {closePopUp, openPopUp, isVisible} = usePopUp({blockScroll: true});
  const isPinTimedOut = pinService.isPinTimedOut();

  const cleanUpAuthorizeCardPaymentDialogState = useCallback(() => {
    setUserDeclined(false);
  }, []);

  const hideAuthorizeCardPaymentDialog = () => {
    setUserDeclined(true);
    closePopUp();
  };

  const showAuthorizeCardPaymentDialog = (authorizeCardAction: CardAuthorizationAction) => {
    if (
      (!actionConsentId.current || actionConsentId.current === authorizeCardAction.consentId) &&
      (isVisible || isPinTimedOut || userDeclined)
    ) {
      console.log(
        '[Card Authorization] Dialog specifically NOT opened',
        JSON.stringify(
          {
            actionConsentId,
            authorizeCardAction,
            isVisible,
            isPinTimedOut,
            userDeclined,
          },
          null,
          2,
        ),
      );
      return;
    }

    if (!!authorizeCardAction) {
      actionConsentId.current = authorizeCardAction.consentId;
      console.log('[Card Authorization] Open dialog');
      openPopUp();
    }
  };

  return {
    isAuthorizeCardPaymentDialogVisible: isVisible,
    showAuthorizeCardPaymentDialog,
    hideAuthorizeCardPaymentDialog,
    cleanUpAuthorizeCardPaymentDialogState,
  };
};
