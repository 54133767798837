import {DEFAULT_RADIUS} from 'src/lib/constants';

import type {HTMLAttributes, MouseEvent as ReactMouseEvent, ReactNode} from 'react';

import {StyledButton, StyledContainer, StyledCTA} from './styled';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  type?: 'primary' | 'primary-icon' | 'secondary' | 'secondary-icon' | 'tertiary';
  cta?: string | undefined;
  invert?: boolean;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
  ghost?: boolean;
  noPadding?: boolean;
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl';
  radius?: number;
  weight?: '400' | 'normal' | 'bold';
  children?: ReactNode;
  as?: keyof JSX.IntrinsicElements;
  onClick?: (e?: ReactMouseEvent) => void;
}

const Button = ({
  type = 'primary',
  cta = undefined,
  invert = false,
  loading = false,
  disabled = false,
  block = false,
  ghost = false,
  noPadding = false,
  size = 'medium',
  radius = DEFAULT_RADIUS,
  weight = 'bold',
  children = null,
  ...props
}: ButtonProps) => {
  if (cta) {
    return (
      <StyledContainer>
        <StyledButton
          $type={type}
          $invert={invert}
          $size={size}
          $loading={loading}
          $block={block}
          $ghost={ghost}
          $noPadding={noPadding}
          $radius={radius}
          $weight={weight}
          disabled={disabled}
          {...props}>
          {children}
        </StyledButton>

        <StyledCTA $type={type} $invert={invert}>
          {cta}
        </StyledCTA>
      </StyledContainer>
    );
  }

  return (
    <StyledButton
      $type={type}
      $invert={invert}
      $size={size}
      $loading={loading}
      $block={block}
      $ghost={ghost}
      $noPadding={noPadding}
      $radius={radius}
      $weight={weight}
      disabled={disabled}
      {...props}>
      {children}
    </StyledButton>
  );
};

export {Button};
