import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthenticationServices } from '@/hooks/login/useAuthenticationServices';
import { DEFAULT_COUNTRY_CODE, DEFAULT_COUNTRY_CODE_FOR_IMAGE, SUPPORTED_COUNTRY_CODES_FOR_IMAGE } from '@/lib/constants/country-code';

type LocationContextType = {
  countryCode: string;
  countryImageCode: string;
  ip: string;
};

const LocationContext = createContext<LocationContextType>({
  countryCode: DEFAULT_COUNTRY_CODE,
  countryImageCode: DEFAULT_COUNTRY_CODE_FOR_IMAGE,
  ip: '',
});

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [countryImageCode, setCountryImageCode] = useState<string>(DEFAULT_COUNTRY_CODE_FOR_IMAGE);
  const [countryCode, setCountryCode] = useState<string>(DEFAULT_COUNTRY_CODE);
  const [ip, setIp] = useState<string>('');
  
  const { userService } = useAuthenticationServices();

  useEffect(() => {
    const fetchLocation = async () => {
      const location = await userService.getUserLocationAndIP();
      const country = location?.geo?.country || DEFAULT_COUNTRY_CODE;

      setIp(location?.ip ?? '');
      setCountryCode(country);

      if (SUPPORTED_COUNTRY_CODES_FOR_IMAGE.includes(country)) {
        setCountryImageCode(country);
      }
    };

    fetchLocation();
  }, [userService]);

  return (
    <LocationContext.Provider value={{ countryCode, ip, countryImageCode }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => useContext(LocationContext);