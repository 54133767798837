import {AssetType, BlockchainAsset, Currency, ExchangeRates} from '@/graphql/__generated__/graphql';
import {BigNumber} from 'bignumber.js';

import {LEADING_ZEROS, RATE_ADJUST_BASE, TRAILING_AFTER_DECIMAL_ZEROS} from '@/constants';

import type {EnterSwapAmountProps} from '@/components/convert/EnterSwapAmount/EnterSwapAmount';
import type {EmptyObject} from '@/types/generics';

import {transformSupportedAssets} from './assets';

interface ConvertedAndFormattedValuesProps {
  sourceCurrency: Currency;
  destCurrency: Currency;
  value: string;
  exchangeRates: ExchangeRates | EmptyObject;
  usdExchangeRate?: number | null;
  supportedBlockchainAssets: BlockchainAsset[];
}

export interface ConvertedAndFormattedValues {
  originalValue: string;
  originalValueFormatted: string;
  convertedValue: string;
  convertedValueFormatted: string;
  exchangedRateFormattedValue: string;
  exchangedRateFormatted: string;
}

interface ConvertedAmountProps {
  amount: number | string | null;
  from: Currency | string | null;
  to: Currency | string | null;
  exchangeRates: ExchangeRates;
  usdExchangeRate: number | null;
  convertFeeRate: number | null;
  isLimitOrder: boolean | null;
}

export type ToFixedArgs = {
  value: number | string | null;
  decimals?: number;
};

export type getConvertedAmountArgs = Partial<ConvertedAmountProps>;

export type getFormattedAmountArgs = Intl.NumberFormatOptions & {
  currency: string;
  value?: number | string | BigNumber;
  hideSeparator?: boolean;
  decimalsLength?: number;
  supportedBlockchainAssets?: BlockchainAsset[];
  omitCurrencySymbol?: boolean;
  locale?: string;
};

export const hideAmount = () => '*****';

export const toFixed = ({value = 0, decimals}: ToFixedArgs) => {
  const amount = new BigNumber(value || 0);
  return trimTrailingZeros(amount.toFormat(decimals || 0, BigNumber.ROUND_HALF_DOWN), decimals)
    .toString()
    .replace(/,/g, '');
};

export const trimTrailingZeros = (value: number | string, decimals: number = 2) => {
  let valueString = value.toString();
  if (valueString.indexOf('.') === -1) return value;

  const [beforeDot, afterDot] = valueString.split('.');
  const numberOfZeros = afterDot.match(/0/g)?.length || 0;
  if (!numberOfZeros) return valueString;
  if (numberOfZeros >= decimals) return `${beforeDot}.00`;
  return valueString.replace(TRAILING_AFTER_DECIMAL_ZEROS, '');
};

export const trimLeadingZeros = (value: string) => {
  if (value.indexOf('.') === -1) {
    return value.replace(LEADING_ZEROS, '');
  }
  return value;
};

export const formatAmount = ({value = '', decimals}: {value: string; decimals: number}) => {
  value = trimLeadingZeros(value);
  const dotPosition = value?.indexOf('.');
  if (dotPosition < 0) {
    return value;
  }
  const shouldFormat = value?.length - dotPosition > decimals;
  const result = shouldFormat ? toFixed({value, decimals}) : value;
  return result;
};

export function getConvertedAmount({
  amount,
  from,
  to,
  exchangeRates,
  usdExchangeRate,
  convertFeeRate,
  isLimitOrder
}: getConvertedAmountArgs) {
  const isToUSD = to === Currency.Usd;
  const areValidExchangeRates = Boolean(exchangeRates) && typeof exchangeRates === 'object';
  const invalidExchangeRate =
    (!areValidExchangeRates && !usdExchangeRate) || (!areValidExchangeRates && !isToUSD);

  if (!amount || !from || !to || invalidExchangeRate) {
    return '0';
  }

  if (from === to) {
    return amount;
  }

  let resultAmount = '0';

  if (isToUSD && usdExchangeRate) {
    resultAmount = new BigNumber(amount).multipliedBy(usdExchangeRate).toString();
  } else if (areValidExchangeRates) {
    const exchangeRate = Number(exchangeRates[(from + to) as keyof ExchangeRates]);

    const toFromRate = Number(exchangeRates[(to + from) as keyof ExchangeRates]);

    const fallbackRate = toFromRate ? new BigNumber(1).dividedBy(toFromRate).toString() : 0;

    resultAmount = new BigNumber(amount).multipliedBy(exchangeRate || fallbackRate).toString();
  }
  if (convertFeeRate) {
    resultAmount = subtractFee(
      resultAmount, 
      convertFeeRate, 
      isLimitOrder || false
    );
  }

  // Avoid returning Infinity or NaN
  return resultAmount === 'Infinity' || resultAmount === 'NaN' ? '0' : resultAmount;
}

export function subtractFee(
  amount: string, 
  feeRate: number,
  isLimitOrder?: boolean
) {
  if (feeRate > 100) {
    return '0';
  }
  if (isLimitOrder) {
    return new BigNumber(amount).multipliedBy(new BigNumber(RATE_ADJUST_BASE).minus(new BigNumber(feeRate))).toString();
  }
  return new BigNumber(amount).minus(new BigNumber(feeRate).dividedBy(100).multipliedBy(amount)).toString();
}

export function getFormattedAmount({
  currency,
  value = 0,
  currencyDisplay = 'symbol',
  minimumIntegerDigits = 2,
  signDisplay = 'auto',
  hideSeparator,
  supportedBlockchainAssets = [], // only necessary to format crypto
  omitCurrencySymbol,
  locale,
  ...settings
}: getFormattedAmountArgs) {
  const amount = BigNumber(value);

  const supportedAssets = transformSupportedAssets(supportedBlockchainAssets) as {
    [key in Currency]: BlockchainAsset;
  };

  let decimalsLength = settings?.decimalsLength;

  // If crypto currency
  if (supportedAssets?.[currency as Currency]?.type === AssetType.Crypto) {
    if (Number(amount) === 0) {
      return `0 ${settings.style ? '' : `${currency.toUpperCase()}`}`;
    }

    if (!decimalsLength) {
      decimalsLength = +supportedAssets[currency as Currency].decimalDigits;
    }
    return `${trimTrailingZeros(amount.toFormat(decimalsLength, BigNumber.ROUND_DOWN), decimalsLength)}${
      settings.style ? '' : ` ${currency.toUpperCase()}`
    }`;
  }

  try {
    const language = locale || 'en'; // update when using i18n
    const formattedAmount = new Intl.NumberFormat(language, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: decimalsLength || 2,
      signDisplay,
      useGrouping: true,
      ...settings,
    }).format(Number(amount));

    if (hideSeparator) {
      return formattedAmount.replace(/,/g, ' ');
    }
    return formattedAmount;
  } catch (error) {
    return `${currency} ${value}`;
  }
}
export const getFormattedPercentage = ({value = 0, ...options}) => {
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  }).format(value);

  return value > 0.0 ? `+${formattedAmount}` : formattedAmount;
};

export const getDefaultFrom = ({
  from,
  to,
  isCashAccountActivated,
  conversionPairBlacklist,
  assetList,
  priority,
}: {
  from: EnterSwapAmountProps['from'];
  to: EnterSwapAmountProps['to'];
  isCashAccountActivated: boolean;
  conversionPairBlacklist?: Record<string, string>;
  assetList: Array<Currency>;
  priority: boolean;
}) => {
  // If we already have a high-prio FROM value, this will maintain the FROM currency and force the TO currency to change if necessary.
  if (priority && from) {
    return from;
  }

  // If we already have a non-blacklisted FROM value, let's return that.
  if (from && to && to !== from && !conversionPairBlacklist?.[from]?.includes(to)) {
    return from;
  }

  // If we don't have a non-blacklisted FROM value, the chaos begins...

  // Let's try setting a basic default.
  // Should be EUR for EEA users. If the "to" value is already EUR, fallback to BTC.
  // Should be BTC for ROW users. If the "to" value is already BTC, fallback to USDC.
  let fromCurency = isCashAccountActivated
    ? to !== Currency.Eur
      ? Currency.Eur
      : Currency.Btc
    : to !== Currency.Btc
      ? Currency.Btc
      : Currency.Usdc;

  // If the chosen pair is still blacklisted, find any other asset that isn't blacklisted!
  if (to && conversionPairBlacklist?.[fromCurency]?.includes(to)) {
    for (let potentialFromCurrency of assetList) {
      if (!conversionPairBlacklist?.[potentialFromCurrency]?.includes(to) && potentialFromCurrency !== to) {
        return potentialFromCurrency;
      }
    }
  }

  return fromCurency || Currency.Usdc;
};

export const getDefaultTo = ({
  from,
  to,
  isCashAccountActivated,
  conversionPairBlacklist,
  assetList,
  priority,
}: {
  from: EnterSwapAmountProps['from'];
  to: EnterSwapAmountProps['to'];
  isCashAccountActivated: boolean;
  conversionPairBlacklist?: Record<string, string>;
  assetList: Array<Currency>;
  priority: boolean;
}) => {
  // If we already have a high-prio TO value, this will maintain the TO currency and force the FROM currency to change if necessary.
  if (priority && to) {
    return to;
  }

  // If we already have a non-blacklisted TO value, let's return that.
  if (from && to && to !== from && !conversionPairBlacklist?.[from]?.includes(to)) {
    return to;
  }

  // If we don't have a non-blacklisted TO value, the chaos begins...

  // Let's try setting a basic default.
  // Should be BTC for EEA users. If the "from" value is already BTC, fallback to EUR.
  // Should be USDC for ROW users. If the "from" value is already USDC, fallback to BTC.
  let toCurrency = isCashAccountActivated
    ? from !== Currency.Btc
      ? Currency.Btc
      : Currency.Eur
    : from !== Currency.Usdc
      ? Currency.Usdc
      : Currency.Btc;

  // If the chosen pair is still blacklisted, find any other asset that isn't blacklisted!
  if (from && conversionPairBlacklist?.[from]?.includes(toCurrency)) {
    toCurrency =
      assetList.find(asset => !conversionPairBlacklist[from]?.includes(asset) && asset !== from) ||
      Currency.Usdc;
  }

  return toCurrency;
};

export const getConvertedAndFormattedValues = ({
  sourceCurrency,
  destCurrency,
  value,
  exchangeRates,
  usdExchangeRate,
  supportedBlockchainAssets,
}: ConvertedAndFormattedValuesProps): ConvertedAndFormattedValues => {
  const originalValueFormatted = getFormattedAmount({
    currency: sourceCurrency,
    value,
    supportedBlockchainAssets,
  });

  const convertedValue = getConvertedAmount({
    amount: value,
    from: sourceCurrency,
    to: destCurrency,
    exchangeRates,
    usdExchangeRate,
  }).toString();

  const convertedValueFormatted = getFormattedAmount({
    currency: destCurrency,
    value: convertedValue,
    supportedBlockchainAssets,
  });

  const fromTo = (sourceCurrency + destCurrency) as keyof ExchangeRates;
  const rate =
    destCurrency === Currency.Usd && usdExchangeRate ? usdExchangeRate : exchangeRates?.[fromTo] || 0;

  const exchangedRateFormattedValue = getFormattedAmount({
    value: Number(rate),
    currency: destCurrency,
  });

  const exchangedRateFormatted = `1 ${sourceCurrency} = ${exchangedRateFormattedValue}`;
  return {
    originalValue: value,
    originalValueFormatted,
    convertedValue,
    convertedValueFormatted,
    exchangedRateFormattedValue,
    exchangedRateFormatted,
  };
};
