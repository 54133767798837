import type {
  GetCashBankDetailsQuery,
  PublishedCardUpdatesSubscription,
} from '@/graphql/__generated__/graphql';

export const afterGet = (
  data: GetCashBankDetailsQuery | undefined,
  subData: PublishedCardUpdatesSubscription | undefined,
) => {
  if (!subData?.publishedCardUpdates) {
    return data?.getCashBankDetails || null;
  }
  const updatedCard = subData.publishedCardUpdates.card;
  const cards = data?.getCashBankDetails?.cards ? [...data?.getCashBankDetails?.cards] : [];

  //Find index of specific object using findIndex method.
  const updatedCardIndex = cards.findIndex(item => item?.id === updatedCard.id);

  if (updatedCardIndex >= 0) {
    cards[updatedCardIndex] = updatedCard;
  } else {
    cards.push(updatedCard);
  }
  return {
    ...data?.getCashBankDetails,
    cards,
  };
};
