import {CardAuthorizationAction, FullPendingActionType} from '@/types/pendingActions';

export const mergePendingActionArrays = (A: FullPendingActionType[], B: FullPendingActionType[]) => {
  // Create a map of consentId to objects in A
  const mapA = A.reduce<Record<string, FullPendingActionType>>((map, obj) => {
    if (obj) {
      map[obj.consentId] = obj;
    }
    return map;
  }, {});

  // Iterate through B and merge data from A (if exists)
  const mergedArray = B.map(objB => {
    if (!objB) return {} as FullPendingActionType;

    const objA = mapA[objB.consentId] || {};
    return {...objA, ...objB};
  });

  return mergedArray;
};

export const comparePendingActionArrays = (A: FullPendingActionType[], B: FullPendingActionType[]) => {
  // Extract consentIds from both arrays
  const ids1 = A.map(obj => obj.consentId);
  const ids2 = B.map(obj => obj.consentId);

  // Check if both sets of consentIds are equal
  const set1 = new Set(ids1);
  const set2 = new Set(ids2);

  if (set1.size !== set2.size) return false; // Sizes must be the same
  for (let id of set1) {
    if (!set2.has(id)) return false; // Every id in set1 must exist in set2
  }

  return true; // All checks passed
};

export const extractAuthCardDataFromPendingAction = (
  pendingAction: Required<FullPendingActionType>,
): CardAuthorizationAction | undefined => {
  const regex = /transaction of ([\d.,]+) (\w{3,5}) at (.+)/;
  const match = pendingAction.description.match(regex);

  if (match) {
    return {
      amount: match[1], // Captures "1.00" or "1,00"
      currency: match[2], // Captures "EUR" or "GBP"
      beneficiary: match[3], // Captures "Merchant"
      redirectUrl: pendingAction.consentData?.redirectUrl || '',
      consentId: pendingAction.consentId,
    };
  }
};

export const toTitleCase = (str: string) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};
