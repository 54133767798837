import { ONE_HOUR_IN_SEC } from '@/constants';
import dayjs from 'dayjs';

export default class PinService {
  private readonly timeoutInSeconds: number;
  private readonly pinTimestampId = 'last_pin_timestamp';

  constructor() {
    this.timeoutInSeconds = process.env.NEXT_PUBLIC_PIN_TIMEOUT_IN_SECONDS
      ? parseInt(process.env.NEXT_PUBLIC_PIN_TIMEOUT_IN_SECONDS)
      : ONE_HOUR_IN_SEC;
  }

  setLastPinTimestamp() {
    window?.sessionStorage?.setItem(this.pinTimestampId, new Date().toISOString());
  }

  getLastPinTimestamp() {
    return window?.sessionStorage?.getItem(this.pinTimestampId);
  }

  isPinTimedOut(): boolean {
    let pinTimedOut = true;
    const lastSuccessfulPin = window?.sessionStorage?.getItem(this.pinTimestampId);
    if (lastSuccessfulPin) {
      const currentTime = dayjs();
      const diff = currentTime.diff(lastSuccessfulPin, 'seconds');
      pinTimedOut = diff > this.timeoutInSeconds;
    }
    return pinTimedOut;
  }

  setRecoveryCode(code: string) {
    window?.sessionStorage?.setItem('recoveryCode', code);
  }

  getRecoveryCode() {
    return window?.sessionStorage?.getItem('recoveryCode');
  }
}

export const pinService = new PinService();
