import {debitCardUpdatedSubscription} from '@/graphql/subscriptions';
import {DebitCardStatus} from '@/types/debitCard';
import {friendlyFormatIBAN} from 'ibantools';

import {afterGet} from '@/controllers/cashAccountDetails';
import {getCashBankDetails} from '@/graphql/queries';
import {useQuery, useSubscription} from '@apollo/client';

import {CARD_TERMINATED_STATUS} from '@/constants';
import {useComplianceContext} from '@/contexts/ComplianceContext';

export const useCashAccountDetails = (skip?: boolean) => {
  const {canSkipKyc} = useComplianceContext();

  const {data, ...useQueryResults} = useQuery(getCashBankDetails, {
    fetchPolicy: 'cache-first',
    skip: skip || !canSkipKyc,
  });
  const {data: subData} = useSubscription(debitCardUpdatedSubscription);

  const account = afterGet(data, subData);
  const formattedIBAN = friendlyFormatIBAN(account?.details?.iban || '');
  const [validCard] = account?.cards || [];

  const userHasValidCard =
    !!validCard && !CARD_TERMINATED_STATUS.includes(validCard!.status as DebitCardStatus);

  return {
    ...useQueryResults,
    account,
    formattedIBAN,
    validCard,
    activeCard: validCard?.status === DebitCardStatus.Active ? validCard : null,
    userHasValidCard,
  };
};
