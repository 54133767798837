import {useRouter} from 'next/router';

import {Button, PopUp, Typography} from '@kasta-io/components';

import {handleContactSupport} from '@/utils/support';

interface Props {
  type: 'cash' | 'crypto';
  isVisible: boolean;
  togglePopUp: () => void;
}

export const KycActionNeededDialog = ({type, isVisible, togglePopUp}: Props) => {
  const router = useRouter();

  const onResubmit = () => {
    togglePopUp();

    if (type === 'crypto') {
      return router.push(`/kyc`);
    }

    return router.push('/cash/kyc/explained');
  };

  const onContactSupport = () => {
    togglePopUp();
    handleContactSupport();
  };

  return (
    <PopUp
      open={isVisible}
      popOver
      onClose={togglePopUp}
      closeOnBackdropClick
      hasCloseButton={false}
      title={
        <>
          Action Needed: <br />
          Resubmit information
        </>
      }
      icon={{
        type: 'custom',
        name: 'yellow-random-column',
      }}
      footer={
        <>
          <Button type={'secondary'} onClick={onContactSupport} block>
            Contact support
          </Button>
          <Button onClick={onResubmit} block>
            Resubmit
          </Button>
        </>
      }>
      <Typography variant="body2" color="var(--colors-text-onBackgroundMedium)">
        We couldn&apos;t verify your information. Please resubmit the required information to proceed.
      </Typography>
    </PopUp>
  );
};
