import mixpanel, {Dict, Query} from 'mixpanel-browser';

import {isDev} from '@/utils/device';
import { getReferrer } from '@/utils/mixpanel';
import { UTM_PARAMS } from '@/lib/constants/mixpanel';

class MixPanel {
  constructor() {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
      debug: isDev,
      track_pageview: 'url-with-path-and-query-string',
      persistence: 'cookie',
      api_host: 'https://api-eu.mixpanel.com',
      record_sessions_percent: 1, //records 1% of all sessions
      cross_subdomain_cookie: true,
      cookie_domain: '.ka.app',
    });
  }

  identify(id?: string) {
    mixpanel.identify(id);
  }

  alias(id: string) {
    mixpanel.alias(id);
  }

  track(event: string, props?: Dict) {
    mixpanel.track(event, {
      ...props,
      referrer: getReferrer(),
    });
  }

  track_links(query: Query, name: string) {
    mixpanel.track_links(query, name, {
      referrer: getReferrer(),
    });
  }

  set(props: Dict) {
    mixpanel.people.set(props);
  }

  reset() {
    mixpanel.reset();
  }

  append(props: Dict) {
    mixpanel.people.append(props);
  }

  register(props: Dict) {
    mixpanel.register(props);
  }

  union(props: Dict) {
    mixpanel.people.union(props);
  }

  getDistinctId() {
    return mixpanel.get_distinct_id();
  }

  setUTMProperties() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmProps: Dict = {};
  
    UTM_PARAMS.forEach(param => {
      const value = urlParams.get(param);
      
      if (value) {
        utmProps[param] = value;
      }
    });
  
    if (Object.keys(utmProps).length > 0) {
      this.set(utmProps);
    }
  }
}

// Ensure this code will only be available for client side
export const mixPanel: MixPanel | null = typeof window !== 'undefined' ? new MixPanel() : null;
