import {version} from '../package.json';

// atoms
export {Avatar, type AvatarProps} from 'src/atoms/Avatar/Avatar';
export {Badge, type BadgeProps} from 'src/atoms/Badge/Badge';
export {Button, type ButtonProps} from 'src/atoms/Button/Button';
export {Card, type CardProps} from 'src/atoms/Card/Card';
export {Checkbox, type CheckboxProps} from 'src/atoms/Checkbox/Checkbox';
export {Divider, type DividerProps} from 'src/atoms/Divider/Divider';
export {
  FloatingActionButton,
  type FloatingActionButtonProps,
} from 'src/atoms/FloatingActionButton/FloatingActionButton';
export {
  Icon,
  type KastaIconProps,
  type CustomIconProps,
  type ImageryIconProps,
  type PaymentIconProps,
  type FlagProps,
} from 'src/atoms/Icon/Icon';
export {Input, type InputProps} from 'src/atoms/Input/Input';
export {Loading, type LoadingProps} from 'src/atoms/Loading/Loading';
export {ProgressBar, type ProgressBarProps} from 'src/atoms/Progress/ProgressBar';
export {ProgressIndicator, type ProgressIndicatorProps} from 'src/atoms/Progress/ProgressIndicator';
export {RadioInput, type RadioInputProps} from 'src/atoms/RadioInput/RadioInput';
export {Skeleton, type SkeletonProps} from 'src/atoms/Skeleton/Skeleton';
export {Toggle, type ToggleProps} from 'src/atoms/Toggle/Toggle';
export {Typography, type TypographyProps} from 'src/atoms/Typography/Typography';

// molecules
export {
  ActionList,
  type ActionListProps,
  type ActionListItemProps,
} from 'src/molecules/ActionList/ActionList';
export {Alert, type AlertProps} from 'src/molecules/Alert/Alert';
export {AlertContainer, type AlertContainerProps} from 'src/molecules/Alert/Container';
export {Banner, type BannerProps} from 'src/molecules/Banner/Banner';
export {
  BulletList,
  type BulletListProps,
  type BulletListItemProps,
} from 'src/molecules/BulletList/BulletList';
export {Dropdown, type DropdownProps, type DropdownItemProps} from 'src/molecules/Dropdown/Dropdown';
export {EmailField, type EmailFieldProps} from 'src/molecules/EmailField/EmailField';
export {Explainer, type ExplainerProps} from 'src/molecules/Explainer/Explainer';
export {KastaTag, type KastaTagProps} from 'src/molecules/KastaTag/KastaTag';
export {List, type ListProps, type ListItemProps} from 'src/molecules/List/List';
export {NumberField, type NumberFieldProps} from 'src/molecules/NumberField/NumberField';
export {PhoneField, type PhoneFieldProps} from 'src/molecules/PhoneField/PhoneField';
export {Pill, type PillProps} from 'src/molecules/Pill/Pill';
export {PinInput, type PinInputProps} from 'src/molecules/PinInput/PinInput';
export {ProgressBanner, type ProgressBannerProps} from 'src/molecules/ProgressBanner/ProgressBanner';
export {QRCode, type QRCodeProps} from 'src/molecules/QRCode/QRCode';
export {SearchField, type SearchFieldProps} from 'src/molecules/SearchField/SearchField';
export {Tabs, type TabsProps, type TabItemProps} from 'src/molecules/Tabs/Tabs';
export {TextField, type TextFieldProps} from 'src/molecules/TextField/TextField';
export {Toast, type ToastProps} from 'src/molecules/Toast/Toast';
export {ToastContainer, type ToastContainerProps} from 'src/molecules/Toast/Container';

// organisms
export {PopUp, type PopUpProps} from 'src/organisms/PopUp/PopUp';
export {FAQ, type FAQProps} from 'src/organisms/FAQ/FAQ';

// templates
export {SplashScreen, type SplashScreenProps} from 'src/templates/SplashScreen/SplashScreen';

// custom hooks
export {useAlert} from 'src/hooks/useAlert';
export {usePopUp} from 'src/hooks/usePopUp';
export {useToast} from 'src/hooks/useToast';

// custom contexts
export {AlertProvider, AlertContext, useAlertContext} from 'src/contexts/alert';
export {PopUpProvider, PopUpContext, usePopUpContext} from 'src/contexts/popup';
export {ToastProvider, ToastContext, useToastContext} from 'src/contexts/toast';

// aliases - atoms
export {FloatingActionButton as FAB} from 'src/atoms/FloatingActionButton/FloatingActionButton';
export {RadioInput as Radio} from 'src/atoms/RadioInput/RadioInput';
export {Toggle as Switch} from 'src/atoms/Toggle/Toggle';
export {Toggle as ToggleSwitch} from 'src/atoms/Toggle/Toggle';
export {Typography as Text} from 'src/atoms/Typography/Typography';

// aliases - molecules
export {PinInput as OTPInput} from 'src/molecules/PinInput/PinInput';

// icons
export {KASTA_ICONS, CUSTOM_ICONS, IMAGERY_ICONS, PAYMENT_ICONS, FLAGS} from 'src/lib/icons';

// types
export type {ThemeType, IconType, TypographyVariants} from 'src/lib/types';

// constants
export const LIB_VERSION = version;
export * as CONSTANTS from 'src/lib/constants';
