import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {classNames} from 'primereact/utils';

import {useNavigationContext} from '@/contexts/NavigationContext';
import useIsPwaInstalled from '@/hooks/useIsPwaInstalled';
import useIsTargetMedia from '@/hooks/useIsTargetMedia';

import PageSpinner from '@/components/layout/PageSpinner';

import s from './PageLayout.module.scss';

const DynamicDesktopNavBar = dynamic(() => import('../layout/navbar/DesktopNavBar/DesktopNavBar'));
const DynamicMobileNavBar = dynamic(() => import('../layout/navbar/MobileNavBar/MobileNavBar'));

interface PageLayoutProps {
  centered?: boolean;
  children: React.ReactNode;
  fullwidth?: boolean;
  useNewUiStyle?: boolean;
  mobileBackgroundColor?: string;
}

const getNavBar = (isMobileDevice: boolean | null) => {
  if (isMobileDevice === null) return null;

  return isMobileDevice ? <DynamicMobileNavBar /> : <DynamicDesktopNavBar />;
};

export default function PageLayout({
  centered = false,
  children,
  fullwidth = false,
  useNewUiStyle,
  mobileBackgroundColor,
}: PageLayoutProps) {
  const router = useRouter();
  const {loading} = useNavigationContext();

  const isDeviceSmallerThanDesktop = useIsTargetMedia('desktop', 'less', true);
  const isMobileDevice = useIsTargetMedia('tablet', 'less', true);
  const isPwaInstalled = useIsPwaInstalled();

  const mainClassName = classNames(s.list, {
    [s.main]: !fullwidth,
    [s.centered]: centered && !fullwidth,
    [s.mainNewUi]: useNewUiStyle,
  });

  return (
    <div
      className={s.container}
      style={isMobileDevice || isPwaInstalled ? {backgroundColor: mobileBackgroundColor} : undefined}>
      {getNavBar(isDeviceSmallerThanDesktop)}
      {loading ? (
        <PageSpinner />
      ) : (
        <main className={mainClassName} key={`main-container-${router.asPath}`}>
          {children}
        </main>
      )}
    </div>
  );
}
