import { getCookies } from "cookies-next";

/**
 * Retrieves the referrer from cookies or falls back to the document referrer.
 * 
 * @returns {string} The referrer URL.
 * 
 */
export const getReferrer = (): string => {
    const cookies = getCookies();
    return cookies['referrer'] || document.referrer;
}