import {createContext, ReactNode, use, useContext, useEffect, useMemo, useState} from 'react';
import {useRouter} from 'next/router';

import {Currency} from '@/types/currency';

import {useAccount} from './AccountContext';

type BuyCryptoContextType = {
  paymentMethod: string;
  setPaymentMethod: (newPaymentMethod: PaymentMethod) => void;
  orderType: OrderType;
  setOrderType: React.Dispatch<React.SetStateAction<OrderType>>;
  viewCurrency: Currency | undefined;
  toggleViewCurrency: () => void;
  switchCurrency?: Currency;
  saveAmount: (cryptoAmount?: string) => void;
  amount?: string;
  availableAmount: number;
};

type PaymentMethod = 'USDC' | 'EUR' | 'CARD';

type OrderType = 'oneTime' | 'limitOrder';

const BuyCryptoContext = createContext<BuyCryptoContextType | null>(null);

export const BuyCryptoProvider = ({children}: {children: ReactNode}) => {
  const router = useRouter();
  const selectedAsset = router.query.asset as unknown as Currency;

  const {accountFormattedCashAssetList, accountFormattedCryptoAssetList} = useAccount();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('EUR');
  const [orderType, setOrderType] = useState<OrderType>('oneTime');
  const [viewCurrency, setViewCurrency] = useState<Currency>();
  const [amount, setAmount] = useState('');
  const [availableAmount, setAvailableAmount] = useState(0);

  const toggleViewCurrency = () => {
    if (viewCurrency === selectedAsset) {
      setViewCurrency(paymentMethod === Currency.Usdc ? Currency.Usdc : Currency.Eur);
    } else {
      setViewCurrency(selectedAsset);
    }
  };

  const switchCurrency = useMemo((): Currency => {
    if (viewCurrency === selectedAsset) {
      switch (paymentMethod) {
        case 'CARD':
          return Currency.Eur;
        case 'USDC':
          return Currency.Usdc;
        case 'EUR':
        default:
          return Currency.Eur;
      }
    } else {
      return selectedAsset;
    }
  }, [viewCurrency, selectedAsset, paymentMethod]);

  const updatePaymentMethod = (newPaymentMethod: PaymentMethod) => {
    let newViewCurrency: Currency;
    setPaymentMethod(newPaymentMethod);

    switch (newPaymentMethod) {
      case 'USDC':
        newViewCurrency = viewCurrency === selectedAsset ? selectedAsset : Currency.Usdc;
        break;
      case 'EUR':
        newViewCurrency = viewCurrency === selectedAsset ? selectedAsset : Currency.Eur;
        break;
      case 'CARD':
        newViewCurrency = viewCurrency === selectedAsset ? selectedAsset : Currency.Eur;
        break;
    }

    setViewCurrency(newViewCurrency);
  };

  const saveAmount = (cryptoAmount?: string) => {
    cryptoAmount && setAmount(cryptoAmount);
  };

  useEffect(() => {
    if (paymentMethod === Currency.Eur) {
      const amount = accountFormattedCashAssetList.find(({currency}) => currency === Currency.Eur)?.amount;
      amount && setAvailableAmount(amount);
    } else if (paymentMethod === Currency.Usdc) {
      const amount = accountFormattedCryptoAssetList.find(({currency}) => currency === Currency.Usdc)?.amount;
      amount && setAvailableAmount(amount);
    }
  }, [accountFormattedCashAssetList, accountFormattedCryptoAssetList, paymentMethod]);

  return (
    <BuyCryptoContext.Provider
      value={{
        paymentMethod,
        setPaymentMethod: updatePaymentMethod,
        orderType,
        setOrderType,
        viewCurrency,
        toggleViewCurrency,
        switchCurrency,
        saveAmount,
        amount,
        availableAmount,
      }}>
      {children}
    </BuyCryptoContext.Provider>
  );
};

export const useBuyCrypto = () => {
  const context = useContext(BuyCryptoContext);
  if (!context) {
    throw new Error('useBuyCrypto must be used within a BuyCryptoProvider');
  }
  return context;
};
