import viewports from '@kasta-io/tokens/primitives/viewport.json';
import {DefaultBlockPadding, DefaultBlockRadius} from 'src/styled/mixins';
import {ptToPx} from 'src/styled/utils';
import styled from 'styled-components';

import type {ThemeType} from 'src/lib/types';

interface PillProps {
  $type: ThemeType;
  $spacing: number;
}

const types = {
  primary: {
    backgroundColor: 'var(--colors-primary-50)',
    icon: 'var(--colors-primary-500)',
  },
  secondary: {
    backgroundColor: 'var(--colors-success-50)',
    icon: 'var(--colors-text-OnBackground)',
  },
  accent: {
    backgroundColor: 'var(--colors-warning-50)',
    icon: 'var(--colors-text-OnBackground)',
  },
  white: {
    backgroundColor: 'var(--colors-neutral-50)',
    icon: 'var(--colors-text-OnBackground)',
  },
};

const StyledPill = styled.div<PillProps>`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  ${DefaultBlockPadding};
  margin: ${({$spacing}: PillProps) => `${ptToPx($spacing)}px`} 0;
  text-align: left;
  background-color: ${({$type}) => `${types[$type].backgroundColor}`};
  ${DefaultBlockRadius};

  @media screen and (max-width: ${viewports['tablet']}) {
    padding: 16px;
  }

  [role='icon'] {
    flex: 0 1 20px;
    margin-right: 8px;
    color: ${({$type}) => `${types[$type].icon}`};
  }

  p {
    flex: 1;
    color: var(--colors-text-onBackgroundMedium);
  }
`;

const StyledCTAContainer = styled.div`
  flex: 1 1 100%;
  margin-top: 8px;
  text-align: right;
`;

export {StyledPill, StyledCTAContainer};
