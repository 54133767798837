import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useRouter} from 'next/router';

import {CardStatus, PendingActionType} from '@/graphql/__generated__/graphql';
import {CardAuthorizationAction, FullPendingActionType} from '@/types/pendingActions';

import {getPendingActions, getRedirectUrlFromConsentId} from '@/graphql/queries';
import {useQuery} from '@apollo/client';

import {ERROR_LEVEL, ORIGIN} from '@/constants';
import {useAuthorizePaymentDialog} from '@/hooks/useAuthorizeCardPaymentDialog';
import {useCashAccountDetails} from '@/hooks/useCashAccountDetails';
import {tracking} from '@/services/tracking/TrackingService';
import {
  comparePendingActionArrays,
  extractAuthCardDataFromPendingAction,
  mergePendingActionArrays,
} from '@/utils/pendingActions';

import {AuthorizePendingCardPaymentDialog} from '@/components/debit-card/AuthorizePendingCardPaymentDialog/AuthorizePendingCardPaymentDialog';

import type {ReactNode} from 'react';

import {useAppConfig} from './AppConfigContext';
import {useUser} from './UserContext';

type PendingActionsContextType = {
  authorizeCardAction?: CardAuthorizationAction;
};

const PendingActionsContext = createContext<PendingActionsContextType>({});

export const usePendingActionsContext = () => useContext(PendingActionsContext);

const PendingActionsContextProvider = ({children}: {children: ReactNode}) => {
  const {isCashAccountActivated} = useUser();
  const {appConfig, loadingAppConfig} = useAppConfig();
  const [pendingActions, setPendingActions] = useState<FullPendingActionType[]>([]);
  const [authorizeCardAction, setAuthorizeCardAction] = useState<CardAuthorizationAction>();
  const {validCard} = useCashAccountDetails(!isCashAccountActivated);
  const router = useRouter();

  const {
    showAuthorizeCardPaymentDialog,
    hideAuthorizeCardPaymentDialog,
    cleanUpAuthorizeCardPaymentDialogState,
    isAuthorizeCardPaymentDialogVisible,
  } = useAuthorizePaymentDialog();

  // STEP 1: Fetch all pending actions
  const {data, error, client} = useQuery(getPendingActions, {
    fetchPolicy: 'cache-first',
    pollInterval: 10000, // 10 seconds
    skip:
      loadingAppConfig ||
      !appConfig.cardAuthorizationEnabled ||
      !isCashAccountActivated ||
      validCard?.status !== CardStatus.Active,
  });

  useEffect(() => {
    if (error) {
      tracking.logError({
        error_message: error.message,
        error_message_id: 'pending-actions/kasta/could-not-fetch-pending-actions',
        error_level: ERROR_LEVEL.WARNING,
      });
    }
  }, [error]);

  // STEP 2: Clean up and merge pending actions
  useEffect(() => {
    // Remove any potential null values
    const latestPendingActions = data?.getPendingActions.filter(a => a?.consentId) as FullPendingActionType[];
    const currentPendingActions = pendingActions;

    // If there are no more pending actions, ie. they have all expired, then clean up the latest action
    if (!latestPendingActions || latestPendingActions.length === 0) {
      setAuthorizeCardAction(undefined);
      cleanUpAuthorizeCardPaymentDialogState();
    }

    const pendingActionsAreEqual = comparePendingActionArrays(
      currentPendingActions,
      latestPendingActions || [],
    );

    if (pendingActionsAreEqual) {
      return; // No need to update
    }

    // No merging required
    if (latestPendingActions?.length > 0 && currentPendingActions?.length == 0) {
      setPendingActions(latestPendingActions);
    }

    // Merge data from existing actions in order to avoid refetching the consent URL
    if (latestPendingActions?.length > 0 && currentPendingActions?.length > 0) {
      const resultingPendingActions = mergePendingActionArrays(pendingActions, latestPendingActions);
      setPendingActions(resultingPendingActions);
    }
  }, [cleanUpAuthorizeCardPaymentDialogState, data, pendingActions]);

  // UTILITY FOR STEP 3
  const getConsentDataForPendingActions = useCallback(
    async (pendingActions: FullPendingActionType[]) => {
      const pendingActionsSubset = await Promise.all(
        pendingActions.map(async pendingAction => {
          // Fetch redirect URL and other consent data for this action
          const {data: consentData} = await client.query({
            query: getRedirectUrlFromConsentId,
            variables: {
              input: {
                consentId: pendingAction.consentId,
                returnUrl: ORIGIN + router.pathname,
              },
            },
          });

          return {
            ...pendingAction,
            consentData: consentData.getIntergiroSCAWithConsentId,
          } as FullPendingActionType;
        }),
      );

      setPendingActions(allPendingActions => {
        return mergePendingActionArrays(pendingActionsSubset, allPendingActions);
      });
    },
    [client, router.pathname],
  );

  // STEP 3: Fetch the consent redirect URL for each action
  useEffect(() => {
    if (!pendingActions) {
      return;
    }

    const pendingActionsWithoutConsentData = pendingActions.filter(
      action => !!action && !!action?.consentId && !action?.consentData,
    ) as Required<FullPendingActionType>[];

    if (pendingActionsWithoutConsentData.length > 0) {
      getConsentDataForPendingActions(pendingActionsWithoutConsentData);
    }
  }, [client, getConsentDataForPendingActions, pendingActions]);

  // STEP 4: Find and parse the latest auth card pending action
  useEffect(() => {
    if (!pendingActions) {
      return;
    }

    const cardAuthPendingActions = pendingActions.filter(a => {
      return a.type === PendingActionType.AuthenticateCardOperation && !!a.consentData?.redirectUrl;
    });

    if (cardAuthPendingActions.length === 0) {
      return;
    }

    cardAuthPendingActions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    const latestAuthCardAction = cardAuthPendingActions[0] as Required<FullPendingActionType>;
    const currentAuthCardAction = authorizeCardAction;

    if (!currentAuthCardAction && latestAuthCardAction) {
      cleanUpAuthorizeCardPaymentDialogState(); // New action, clean state
      setAuthorizeCardAction(extractAuthCardDataFromPendingAction(latestAuthCardAction));
    }

    if (!!currentAuthCardAction && currentAuthCardAction.consentId !== latestAuthCardAction.consentId) {
      cleanUpAuthorizeCardPaymentDialogState(); // New action, clean state
      setAuthorizeCardAction(extractAuthCardDataFromPendingAction(latestAuthCardAction));
    }
  }, [authorizeCardAction, cleanUpAuthorizeCardPaymentDialogState, pendingActions]);

  // STEP 5: Show/hide auth card dialog
  useEffect(() => {
    if (!authorizeCardAction && isAuthorizeCardPaymentDialogVisible) {
      hideAuthorizeCardPaymentDialog();
    }

    if (!!authorizeCardAction && !isAuthorizeCardPaymentDialogVisible) {
      showAuthorizeCardPaymentDialog(authorizeCardAction);
    }
  }, [
    authorizeCardAction,
    hideAuthorizeCardPaymentDialog,
    isAuthorizeCardPaymentDialogVisible,
    showAuthorizeCardPaymentDialog,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({authorizeCardAction}), [authorizeCardAction?.consentId]);

  return (
    <PendingActionsContext.Provider value={contextValue}>
      <AuthorizePendingCardPaymentDialog
        isVisible={isAuthorizeCardPaymentDialogVisible}
        authorizeCardAction={authorizeCardAction}
        onClose={hideAuthorizeCardPaymentDialog}
      />
      {children}
    </PendingActionsContext.Provider>
  );
};

export default PendingActionsContextProvider;
