import {Currency} from '@/types/currency';
import {CardAuthorizationAction} from '@/types/pendingActions';
import {Button, PopUp, Typography} from '@kasta-io/components';

import {useAccount} from '@/contexts/AccountContext';
import {getFormattedAmount} from '@/utils/amounts';
import {getLocale} from '@/utils/locale';
import {toTitleCase} from '@/utils/pendingActions';

import s from './AuthorizePendingCardPaymentDialog.module.scss';

interface Props {
  isVisible: boolean;
  authorizeCardAction?: CardAuthorizationAction;
  onClose: () => void;
}

export const AuthorizePendingCardPaymentDialog = ({isVisible, authorizeCardAction, onClose}: Props) => {
  const {supportedBlockchainAssets} = useAccount();
  const locale = getLocale();

  return (
    <PopUp
      closeOnBackdropClick={false}
      open={isVisible}
      onClose={onClose}
      hasCloseButton={false}
      contentAlignment="center"
      title={'Authorize Card Payment'}
      footer={
        <>
          <Button className={s.button} block onClick={onClose} type="secondary">
            Close
          </Button>
          <Button
            className={s.button}
            block
            onClick={() => {
              if (authorizeCardAction?.redirectUrl) {
                window.location.href = authorizeCardAction.redirectUrl;
              }
            }}>
            Authorize Payment
          </Button>
        </>
      }>
      <div className={s.sections}>
        <div className={s.section}>
          <Typography variant="caption1" color="var(--colors-text-onBackgroundLight)">
            Amount
          </Typography>
          <Typography variant="subtitle2" color="var(--colors-text-onBackgroundMedium)">
            {getFormattedAmount({
              currency: authorizeCardAction?.currency || Currency.Eur,
              value: authorizeCardAction?.amount,
              supportedBlockchainAssets,
              locale,
            })}
          </Typography>
        </div>

        <div className={s.section}>
          <Typography variant="caption1" color="var(--colors-text-onBackgroundLight)">
            Beneficiary
          </Typography>
          <Typography variant="subtitle2" color="var(--colors-text-onBackgroundMedium)">
            {toTitleCase(authorizeCardAction?.beneficiary || '')}
          </Typography>
        </div>
      </div>
    </PopUp>
  );
};
