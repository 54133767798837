import {useMemo} from 'react';

import {useNumberFormat} from '@react-input/number-format';
import {Input} from 'src/atoms/Input/Input';
import {StyledAssistiveText, StyledLabel} from 'src/atoms/Input/styled';
import {DEFAULT_CURRENCY} from 'src/lib/constants';
import {IntRange} from 'type-fest';

import type {InputProps} from 'src/atoms/Input/Input';

import {StyledContainer, StyledNumberField} from './styled';

export interface NumberFieldProps extends InputProps {
  label?: string;
  floatingLabel?: boolean;
  spacing?: IntRange<0, 100>;
  value?: number;
  format?: 'decimal' | 'currency';
  allowDecimals?: boolean;
  allowNegative?: boolean;
  prefix?: string;
  suffix?: string;
  step?: number;
  decimalSeparator?: string;
  groupSeparator?: string;
  locale?: Intl.Locale['baseName'];
  currency?: Intl.NumberFormatOptions['currency'];
  currencyDisplay?: Intl.NumberFormatOptions['currencyDisplay'];
  currencySign?: Intl.NumberFormatOptions['currencySign'];
  grouping?: Intl.NumberFormatOptions['useGrouping'];
}

const NumberField = ({
  label = '',
  floatingLabel = true,
  spacing = 0,
  format = 'decimal',
  locale = 'en-US',
  currency = 'USD',
  currencyDisplay = 'symbol',
  grouping = false,
  ...props
}: NumberFieldProps) => {
  const options = useMemo(
    () =>
      format === 'decimal'
        ? {
            locales: locale,
            format,
            maximumFractionDigits: 8,
            groupDisplay: grouping,
          }
        : {
            locales: locale,
            format,
            currency: currency !== '' ? currency : DEFAULT_CURRENCY,
            currencyDisplay,
            maximumFractionDigits: 8,
            groupDisplay: grouping,
          },
    [format, locale, currency, currencyDisplay, grouping],
  );

  const inputRef = useNumberFormat(options);

  return (
    <StyledContainer $spacing={spacing}>
      <StyledNumberField>
        {label !== '' && !floatingLabel && (
          <StyledLabel $floating={floatingLabel} htmlFor={props.name}>
            {label}
          </StyledLabel>
        )}

        <Input ref={inputRef} type="text" inputMode="decimal" floatingLabel={floatingLabel} {...props} />

        {label !== '' && floatingLabel && (
          <StyledLabel $floating={floatingLabel} htmlFor={props.name} data-floating={true}>
            {label}
          </StyledLabel>
        )}
      </StyledNumberField>

      {props?.error && <StyledAssistiveText>{props.error}</StyledAssistiveText>}
    </StyledContainer>
  );
};

export {NumberField};
