import {InternalError} from '@/errors/InternalError';
import {UserStatusError} from '@/errors/UserStatusError';
import {ValidationError} from '@/errors/ValidationError';
import {UserStatus} from '@/graphql/__generated__/graphql';
import {ServiceApolloErrors, ServiceInternalErrors, ServiceValidationErrors} from '@/types/serviceErrors';
import {ERROR_MESSAGE_ID} from '@/types/tracking';
import {FirebaseError} from 'firebase/app';
import {fetchSignInMethodsForEmail, signInAnonymously, signOut} from 'firebase/auth';
import * as yup from 'yup';

import {getUserStatus, userExists as userExistsByPhoneNumber} from '@/graphql/queries';
import {ApolloClient} from '@apollo/client';
import {ApolloError} from '@apollo/client/errors';

import {ERROR_LEVEL} from '@/constants';
import {fbAuth} from '@/lib/firebase';
import {isNotError} from '@/utils/error';

import {mixPanel} from '../tracking/analytics/MixPanel';
import TrackingService from '../tracking/TrackingService';
import {
  AuthenticationMethodType,
  DataBaseUserEmailProps,
  HandleAuthenticationErrorProps,
  IAuthenticationMethodAbstract,
} from './IAuthenticationMethod';

export class BaseAuthenticationMethod extends IAuthenticationMethodAbstract {
  /*
   * Long list of possible generic errors
   */

  static APOLLO_ERROR: ServiceApolloErrors = {
    MULTIPLE_SIGN_IN_REQUESTS: {
      CODE: 'auth/kasta/multiple-sign-in-requests',
      MATCH: /Multiple (login|sign-in) requests within 2 minutes/,
      MSG: 'Multiple login requests detected. Please wait a moment and try again.',
      LVL: ERROR_LEVEL.NOTICE,
    },
    INVALID_VERIFICATION_CODE: {
      CODE: 'auth/kasta/invalid-verification-code',
      MATCH: /Invalid (validation|sign-in) code|code is invalid/,
      MSG: 'Invalid verification code. Please check that you entered it correctly.',
      LVL: ERROR_LEVEL.NOTICE,
    },
    USER_NOT_FOUND_BY_ID: {
      CODE: 'auth/kasta/user-id-not-found',
      MATCH: /User \[.*\] not found/,
      MSG: "Sorry, we couldn't find this user. Please contact customer support.",
      LVL: ERROR_LEVEL.CRITICAL,
    },
    USER_NOT_FOUND_BY_EMAIL: {
      CODE: 'auth/kasta/user-email-not-found',
      MATCH: /User .*@.* not found/,
      MSG: "Could not send email to user because user couldn't be found.",
      LVL: ERROR_LEVEL.CRITICAL,
    },
  };

  static VALIDATION_ERROR: ServiceValidationErrors = {
    AUTH_ALREADY_EXISTS_IN_DB: {
      CODE: 'auth/kasta/auth-already-exists-in-db',
      MSG: 'User authentication record already exists.',
    },
    USER_ALREADY_EXISTS_IN_DB: {
      CODE: 'auth/kasta/user-already-exists-in-db',
      MSG: 'User already exists.',
    },
    USER_NOT_FOUND_IN_DB: {
      CODE: 'auth/kasta/user-not-found-in-db',
      MSG: 'User does not exist.',
    },
    USER_NOT_LINKED_IN_FB: {
      CODE: 'auth/kasta/user-not-linked-in-fb',
      MSG: 'User not linked.',
    },
    IS_NEW_USER: {
      CODE: 'auth/kasta/is-new-user',
      MSG: 'This is a new user.',
    },
    RESUME_SIGN_UP: {
      CODE: 'auth/kasta/resume-sign-up',
      MSG: 'Account creation incomplete. User should resume sign-up flow.',
    },
    USER_ALREADY_LOGGED_IN: {
      CODE: 'auth/kasta/user-already-logged-in',
      MSG: "It seems you're already logged in.",
    },
  } as const;

  static FIREBASE_ERROR = {
    'auth/provider-already-linked':
      "You've already linked your phone number to your user. Please contact customer support.",

    'auth/captcha-check-failed': 'ReCAPTCHA response token was invalid. Please contact customer support.',

    'auth/missing-phone-number': 'Phone number is missing. Please contact customer support.',

    'auth/quota-exceeded': 'SMS quota exceeded. Please contact customer support.',

    'auth/user-disabled': 'The user has some pending actions. Please contact customer support.',

    'auth/credential-already-in-use': 'Credential already in use. Please contact customer support.',

    'auth/account-exists-with-different-credential':
      'Account exists with a different credential. Please contact customer support.',

    'auth/operation-not-allowed': 'Operation not allowed. Please contact customer support.',

    'auth/invalid-phone-number': 'Invalid phone number.',

    'auth/invalid-email': 'Invalid email.',

    'auth/invalid-verification-code':
      'Invalid verification code. Please check that you entered it correctly.',

    'auth/too-many-requests':
      "We've identified an excessive number of login attempts. Please try again later.",

    'auth/network-request-failed':
      'Network request failed. Please check your internet connection and try again.',

    'auth/auth-domain-config-required': 'Auth domain required. Please contact support.',

    'auth/cancelled-popup-request': 'Popup request was cancelled. Only the most recent popup should work.',

    'auth/operation-not-supported-in-this-environment':
      'Operation not supported in this environment. Please contact support.',

    'auth/unauthorized-domain': 'Unauthorized domain. Please contact support.',

    'auth/popup-blocked': 'Popup blocked.',

    'auth/popup-closed-by-user': 'Popup closed by user.',

    'auth/email-already-in-use': 'Email already in use. Please contact support.',

    'auth/code-expired': "Code expired. Click on 'resend code' below to receive a new one.",

    'auth/error-code:-39': 'Too many sign-in attempts using the same phone number. Try again later.',
  };

  static INTERNAL_ERROR: ServiceInternalErrors = {
    FB_AUTH_NOT_FOUND: {
      CODE: 'auth/kasta/firebase-auth-obj-not-found',
      MSG: 'Firebase Auth object not found.',
    },
  } as const;

  /*
   * Static flags
   */

  static THROW_ON = {
    USER_ALREADY_EXISTS: 'user_already_exists',
    AUTH_ALREADY_EXISTS: 'auth_already_exists',
    USER_NOT_FOUND: 'user_not_found',
  } as const;

  static OPERATION = {
    SIGN_IN: 'sign_in',
    LINK: 'link',
  } as const;

  /*
   * Constructor and private properties
   */

  protected client: ApolloClient<any>;
  protected tracking: TrackingService;
  private storageNameIdentifier: string;
  private storageNameLinking: string;

  constructor(tracking: TrackingService, client: ApolloClient<any>, storageName: string) {
    super();
    this.storageNameIdentifier = storageName + '_identifier';
    this.storageNameLinking = storageName + '_linking';
    this.tracking = tracking;
    this.client = client;
  }

  /*
   * Saving identifier in local storage
   * Each authentication method has its own store
   */

  removeIdentifier() {
    localStorage.removeItem(this.storageNameIdentifier);
  }

  saveIdentifier(identifier: string) {
    if (identifier) {
      localStorage.setItem(this.storageNameIdentifier, identifier);
    }
  }

  getIdentifier(): string | null {
    return localStorage.getItem(this.storageNameIdentifier);
  }

  getLastIdentifier(): string | null {
    return (
      localStorage.getItem('email_authentication' + this.storageNameIdentifier) ||
      localStorage.getItem('google_authentication' + this.storageNameIdentifier) ||
      localStorage.getItem('phone_number_authentication' + this.storageNameIdentifier)
    );
  }

  getStringIdentifier() {
    return typeof this.getIdentifier() === 'string' ? (this.getIdentifier() as string) : null;
  }

  /*
   * Setting and clearing the linking flag
   * Each authentication method has its own store
   */

  public shouldLinkMethod() {
    sessionStorage.setItem(this.storageNameLinking, 'true');
  }

  public clearShouldLinkStatus() {
    sessionStorage.removeItem(this.storageNameLinking);
  }

  public getShouldLinkStatus() {
    const status = sessionStorage.getItem(this.storageNameLinking);
    return status === 'true';
  }

  /*
   * Saving last authentication method in local storage
   * All authentication methods share the same store
   */

  static LAST_AUTH_METHOD_STORAGE = 'last_authentication_method';

  saveLastAuthenticationMethod(method: AuthenticationMethodType) {
    localStorage.setItem(BaseAuthenticationMethod.LAST_AUTH_METHOD_STORAGE, method);
  }

  getLastAuthenticationMethod = () => {
    return localStorage.getItem(
      BaseAuthenticationMethod.LAST_AUTH_METHOD_STORAGE,
    ) as AuthenticationMethodType;
  };

  /*
   * Sign out
   */

  async signOut() {
    sessionStorage.clear();

    // Remove authentication-related flags and IDs
    localStorage?.removeItem('email_authentication' + this.storageNameIdentifier);
    localStorage?.removeItem('google_authentication' + this.storageNameIdentifier);
    localStorage?.removeItem('phone_number_authentication' + this.storageNameIdentifier);
    localStorage?.removeItem(BaseAuthenticationMethod.LAST_AUTH_METHOD_STORAGE);

    this.tracking.logEvent(this.tracking.events.log_out());
    await signOut(fbAuth);

    // PrivateRouteSafetyControl will redirect the use to login once they're annonymous
    await signInAnonymously(fbAuth);
    await this.client.clearStore();

    // after logout:
    mixPanel?.reset();
  }

  /*
   * Shared validation schemas
   */

  static VERIFICATION_CODE_SCHEMA = yup
    .object({
      verificationCode: yup
        .string()
        .strict()
        .matches(/^\d+$/, 'Must contain only numbers')
        .min(6, 'Must be at least 6 characters')
        .max(6, 'Must be at most 6 characters')
        .required('Verification code required'),
    })
    .required();

  /*
   * General error handling code
   */

  handleAuthenticationError({
    error,
    setError,
    fallbackErrorMsg,
    shouldThrow,
    onIsNewUser,
    onUserNotFound,
    onUserNotLinked,
    onUserAlreadyExists,
    onUserStatusError,
    onUserAlreadyLoggedIn,
  }: HandleAuthenticationErrorProps) {
    const throwIfRequested = () => {
      if (shouldThrow) throw error;
    };

    const fallback = () => {
      if (setError && fallbackErrorMsg) {
        setError(fallbackErrorMsg);
      }
    };

    if (error instanceof UserStatusError) {
      return onUserStatusError?.(error.status);
    }

    // Validation errors are usually a special case.
    // The callinng component decides if it wants to set an error or throw an error from these validation functions.
    if (error instanceof ValidationError) {
      if (error.message.includes(BaseAuthenticationMethod.VALIDATION_ERROR.USER_NOT_FOUND_IN_DB.CODE)) {
        return onUserNotFound ? onUserNotFound() : fallback();
      }

      if (error.message.includes(BaseAuthenticationMethod.VALIDATION_ERROR.USER_NOT_LINKED_IN_FB.MSG)) {
        return onUserNotLinked ? onUserNotLinked() : fallback();
      }

      if (error.message.includes(BaseAuthenticationMethod.VALIDATION_ERROR.USER_ALREADY_EXISTS_IN_DB.MSG)) {
        return onUserAlreadyExists ? onUserAlreadyExists() : fallback();
      }

      if (error.message.includes(BaseAuthenticationMethod.VALIDATION_ERROR.IS_NEW_USER.MSG)) {
        return onIsNewUser ? onIsNewUser() : fallback();
      }

      if (error.message.includes(BaseAuthenticationMethod.VALIDATION_ERROR.USER_ALREADY_LOGGED_IN.MSG)) {
        return onUserAlreadyLoggedIn ? onUserAlreadyLoggedIn() : fallback();
      }
    }

    if (!setError) {
      return throwIfRequested();
    }

    if (error instanceof ApolloError) {
      if (BaseAuthenticationMethod.APOLLO_ERROR.INVALID_VERIFICATION_CODE.MATCH.test(error.message)) {
        setError(BaseAuthenticationMethod.APOLLO_ERROR.INVALID_VERIFICATION_CODE.MSG);
        return throwIfRequested();
      }

      if (BaseAuthenticationMethod.APOLLO_ERROR.MULTIPLE_SIGN_IN_REQUESTS.MATCH.test(error.message)) {
        setError(BaseAuthenticationMethod.APOLLO_ERROR.MULTIPLE_SIGN_IN_REQUESTS.MSG);
        return throwIfRequested();
      }
    }

    if (error instanceof FirebaseError) {
      setError(BaseAuthenticationMethod.FIREBASE_ERROR[error.code as FirebaseErrorCodes]);
      return throwIfRequested();
    }

    if (error instanceof InternalError) {
      setError(error.message);
    }

    if (!fallbackErrorMsg) {
      return throwIfRequested();
    }

    setError(fallbackErrorMsg);
    return throwIfRequested();
  }

  /**
   * @param email The email to check if exists in Firebase records
   * @returns {boolean} If email exists
   */
  async doesUserExistsInFirebase(email: string): Promise<boolean> {
    try {
      // This will error if user doesn't exist
      await fetchSignInMethodsForEmail(fbAuth, email);
      // Even if this user has an email identifier and no provider, this is acceptable
      // because the custom token login doesn't require any provider
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * @param email The email to check if exists in our database
   * @returns {DataBaseUserEmailProps} The user status and if exists
   */
  async doesEmailExistsInDatabase(email: string): Promise<DataBaseUserEmailProps> {
    try {
      const response = await this.client.query({query: getUserStatus, variables: {email}});

      // Older users don't have a `userStatus` in the database and the returned value will be `null`
      // If so, we transform `null` into `PERSONAL_DETAILS_SET` and accept it.
      const userStatus = (response?.data?.getUserStatus as UserStatus) || 'PERSONAL_DETAILS_SET';
      const userExistsInDB = [
        UserStatus.PersonalDetailsSet,
        UserStatus.Active,
        UserStatus.Created,
        UserStatus.PasswordSet,
      ].includes(userStatus);

      return {userStatus, userExistsInDB};
    } catch (error) {
      // This query will throw a user-not-found error if the user doesn't exist
      // This makes sense for login/resume-signup but not first-signup
      if (
        error instanceof ApolloError &&
        BaseAuthenticationMethod.APOLLO_ERROR.USER_NOT_FOUND_BY_EMAIL.MATCH.test(error.message)
      ) {
        return {userStatus: null, userExistsInDB: false};
      }

      // Otherwise, some other error was thrown and this one can bubble up
      throw error;
    }
  }

  /**
   * @param phone The phone to check if exists in our database
   * @returns {boolean} If phone exists
   */
  async doesPhoneExistsInDatabase(phone: string): Promise<boolean> {
    const response = await this.client.query({
      query: userExistsByPhoneNumber,
      variables: {phone},
    });

    return !!response?.data?.userExists;
  }

  /**
   * Handles errors that are not considered critical.
   * Logs the error and rethrows it if the error code is not considered an error.
   *
   * @param {unknown} error - The error object to handle.
   * @throws Will throw the error if the error code is not considered an error.
   */
  handleIsNotError(error: unknown): void {
    const {code} = error as {code: ERROR_MESSAGE_ID};
    if (isNotError(code)) {
      this.tracking.logError({
        error_message: (error as Error).message || JSON.stringify(error),
        error_level: ERROR_LEVEL.INFORMATIONAL,
        error_message_id: code,
      });

      throw error;
    }
  }
}

export type FirebaseErrorCodes = keyof typeof BaseAuthenticationMethod.FIREBASE_ERROR;
