import {ptToPx} from 'src/styled/utils';
import styled from 'styled-components';

interface ContainerProps {
  $spacing: number;
}

const StyledContainer = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px`};
`;

const StyledNumberField = styled.div`
  position: relative;
`;

export {StyledContainer, StyledNumberField};
