import {useCallback, useMemo, useRef} from 'react';
import {useRouter} from 'next/router';

import dayjs from 'dayjs';

import * as queries from '@/graphql/queries';

import {useGraphQLClientForLazyQuery} from '@/hooks/useGraphQLClient';
import {ORIGIN, ScaScope, ScaStatus} from '@/lib/constants';

import type {SCAInput, SCAResponse} from '@/types/scaResponse';
import type {ScaScope as ScaScopeType} from '@/types/scaScope';
import type {ScaStatus as ScaStatusType} from '@/types/scaStatus';

export const useSCAQuery = (scope: ScaScopeType, skip: boolean) => {
  const {asPath} = useRouter();

  const returnUrl = `${ORIGIN}${asPath}`;
  const getIntergiroSCAParams = useMemo(
    () => ({
      skip,
      fetchPolicy: 'cache-first' as const,
      variables: {
        input: {
          type: scope,
          returnUrl,
        },
      },
      notifyOnNetworkStatusChange: true,
    }),
    [scope, returnUrl, skip],
  );

  const [getIntergiroSCA, {data, previousData, client, loading, called}] = useGraphQLClientForLazyQuery<
    SCAResponse,
    SCAInput
  >(queries.getIntergiroSCA, getIntergiroSCAParams);

  // Fetch data that might already be in cache from calling this query in other screens.
  const cacheData = client.readQuery({
    query: queries.getIntergiroSCA,
    variables: getIntergiroSCAParams.variables,
  });

  const _data = data?.getIntergiroSCA || previousData?.getIntergiroSCA || cacheData?.getIntergiroSCA;

  const refetch = useCallback(() => {
    /*
     * Refetches all active getIntergiroSCA queries.
     */
    return client.refetchQueries({
      updateCache(cache) {
        cache.modify({
          fields: {
            getIntergiroSCA(_, {INVALIDATE}) {
              return INVALIDATE;
            },
          },
        });
      },
      onQueryUpdated(observableQuery) {
        return observableQuery.variables?.input?.type === scope;
      },
    });
  }, [client, scope]);

  const hasCheckedHistorySCAExpiration = useRef(false);

  const refetchSCAIfNeeded = useCallback(
    async (localScaStatus?: ScaStatusType | null) => {
      const remoteScaData = _data;

      const anErrorOccurred = remoteScaData === null || remoteScaData === undefined;
      const userDoesNotHaveSCA = !!remoteScaData?.redirectUrl && !!remoteScaData?.expiresAt;
      const userDoesHaveSCA = !!remoteScaData?.result;

      const linkHasExpiredBecauseUserRejected = localScaStatus === ScaStatus.Rejected;
      const linkHasExpiredForUnknownReasons = localScaStatus === ScaStatus.InvalidLink;
      const userHasJustAcceptedSCA = localScaStatus === ScaStatus.Approved;

      /*
       * Data is only null if previous attempts at fetching have resulted in error.
       * Try to perform an initial fetch again.
       */
      if (anErrorOccurred) {
        const response = await getIntergiroSCA();
        return response.data?.getIntergiroSCA;
      }

      /*
       * If user doesn't have SCA, "expiresAt" refers to the SCA LINK expiration.
       * If not expired, return from cache.
       */
      if (userDoesNotHaveSCA && !userHasJustAcceptedSCA) {
        const saferExpiration = dayjs(_data?.expiresAt).subtract(1, 'minute');
        const linkHasExpiredBecauseOfTime = saferExpiration && dayjs().isAfter(saferExpiration);

        if (
          !linkHasExpiredBecauseOfTime &&
          !linkHasExpiredBecauseUserRejected &&
          !linkHasExpiredForUnknownReasons
        ) {
          return _data;
        }
        /**
         * If user has rejected the SCA, just return the current data
         */
        if (linkHasExpiredBecauseUserRejected) {
          return _data;
        }
      }

      /*
       * The BALANCE SCA is a one-time auth, so if they already have SCA, no need to refresh.
       */
      if (userDoesHaveSCA && scope === ScaScope.Balance) {
        return _data;
      }

      /*
       * If the user does have HISTORY SCA, we must (for now) hit the API to check if it's expired.
       * But we only want to run this check one time for each instance of the useCashAuth hook.
       */
      if (userDoesHaveSCA && scope === ScaScope.History) {
        if (hasCheckedHistorySCAExpiration.current) {
          return _data;
        }

        hasCheckedHistorySCAExpiration.current = true;
      }

      /**
       * If user has just acceptedSCA it needs to check the result from API since the user params
       * can be updated hardcoded, so it's important to refetch SCA one more time if there is no data
       */
      if (userHasJustAcceptedSCA && _data) {
        return _data;
      }

      /*
       * If the user doesn't have SCA and the SCA link has expired, we must refresh it.
       *
       * When the fetch policy is cache-first, refetches normally fetch data from cache, not the server.
       * If we want to *force* a refetch to perform a network call, we must first invalidate the query.
       */
      const response = await refetch();
      const refetched = response[0]?.data as SCAResponse;
      return refetched?.getIntergiroSCA;
    },
    [_data, getIntergiroSCA, refetch, scope],
  );

  return {
    getIntergiroSCA,
    refetchSCAIfNeeded,
    refetch,
    data: _data,
    loading: loading || (!skip && !called),
  };
};
